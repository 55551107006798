<template>
  <div>
    <div class="page-title-area bg-7" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="page-title-content">
          <h2>PEACEJAM'S SPECIAL JURY AWARD RULES</h2>
          <ul>
            <li>
              <router-link to="/">
                Home
              </router-link>
            </li>

            <li class="active">Official Rules</li>
          </ul>
        </div>
      </div>
    </div>

    <section class="terms-conditions ptb-100" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div class="container">
        <div class="single-privacy">
          <h2 style="color: rgb(38, 198, 218);">
          </h2>
          <div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">1. PURPOSE</h3>
              The purpose of this Special Jury Prize is to: A. Promote thespirit of the Nobel Peace Prize within the author’s documentary or TV episode.B. Favor the expression of humanistic values of tolerance and respectbetween human beings of peace, friendship and understanding betweenpeoples. C. Reject all sorts of propaganda in any form. D. Ensure a wideinternational broadcast of Special Jury Prize Awardee and the programs thatare designated as finalists. The management of this Special Jury Prize isensured   by   PeaceJam’s   Executive   Director   who   is   responsible   for   alladministrative and organizational aspects.
            </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">2. PARTICIPATION</h3>
              The participation in the 4th PeaceJam Special Jury Prizeis open to public and private television organizations as well as to theaudiovisual and other associations and institutions which devote themselvesto   public   understanding.   To   be   considered   for   the   Special   Jury   Prize,programs should be made for television broadcast, but not necessarily limitedto television distribution. The producers or directors of documentaries whowish to compete for the PeaceJam Special Jury Prize will have to submit theirprograms   for   the   preliminary   selection   of   the   organizations   entitled   toparticipate. Programs should be made/broadcast for television content first(can be revealed in theaters after). The participants may propose for thecompetition all programs including documentaries, without limit of length ortheme. However only the programs produced less than two years before thedate of the announcement of the award- winners, that is to say after January1, 2019, will be acceptable. The participants guarantee the sending of theprograms to the headquarters PeaceJam at their own expense with themention “no commercial value” on the parcel. The programs will be acceptedin the competition only once. The participants in the competition committhemselves to ensure the promotion of the PeaceJam Special Jury Prize asfar as possible.
            </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">3. TECHNICAL NORMS</h3>
              The television or documentary programs should beentered in the competition in digital formats (Online Submission, USB device,DVD, ect.).
            </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">4. LANGUAGES</h3>
              Programs should be entered as far as possible - in originalversion, dubbed or subtitled in French and English (or with separate subtitlefiles).&nbsp;</span>
            </div>
            <div style="text-align: justify;"><br></div>
            <div style="text-align: justify;">
              <h3 class="mt-0">5. ACCOMPANYING DOCUMENTS</h3>
              <span style="font-size: 18px;">
                The   programs   competing   for   thePeaceJam Special Jury Prize must absolutely come with their registrationform duly filled, with a summary in English. In general, all publicity orpromotional material concerning the entered documentaries is welcome, withthe relating authorization of free advertisement in the press.
              </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">6. CO-PRODUCTIONS</h3>
              A program made in co-production by two or severalorganizations may only be entered for competition by one of the co-producingorganizations, under its sole responsibility.
            </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">7. REGISTRATION</h3>
              </span><span style="font-size: 18px; font-weight: 300;">
              Registration of the entered programs will be definitelyclosed at the latest fourteen days before the date of the preselection, exceptdispensation granted by the PeaceJam Management. The order of entering ofthe programs is established by the PeaceJam Management, except explicitdecision of the jury.
            </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">8. REGISTRATION FEES</h3>
              No registration fees will be asked of the participating organizations.</span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">9. JURYS’ WORK</h3>
              The General Management makes a first selection of the registered programs on the basis of the required technical criteria and of their conformity to the present rules. The preselection jury’s deliberations and votes and those of the final jury are secret. The jury views the programs in consideration and sets the evaluation criteria considering the object of the Special Jury Prize. PeaceJam sets the rules of deliberation.
            </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">10. PRESELECTION JURY</h3>
              The preselection jury is made up of the PeaceJam Co-Founders and of representatives of the Board of Directors. After viewing all the programs admitted for participation, and after a valid deliberation, the preselection jury makes up the list of a maximum of three finalists.
            </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">11. FINAL JURY</h3>
              The final jury is made up of the President (a philanthropic personality), the PeaceJam Co-Founders, a Monaco Representative chosen by HSH Prince Albert II in consultation with the Festival de Television, and other distinguished personalities in the humanitarian, diplomatic and television network, the number of which, is at the discretion of the PeaceJam organization.
            </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">12. PEACEJAM’S SPECIAL JURY PRIZE- SELECTION OF WINNER</h3>
              After viewing the three programs with the «finalist» designation and after a valid deliberation, the jury awards the prize of the International PeaceJam Special Jury Prize.
            </span>
            </div>
            <div style="text-align: justify;"><span
                    style="font-size: 18px; background-color: rgba(0, 0, 0, 0);"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px; background-color: rgba(0, 0, 0, 0);">
              <h3 class="mt-0">13. DECISION</h3>
              The jury’s decisions are final and irrevocable.</span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><br></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">14. BROADCAST OF THE AWARDED WORKS AND WITH THE FINALIST STATUTE</h3>
              Each prize winning work should bear the mention: “PeaceJam Special Jury Prize Winner (with the year)” in the credits when it received the Special Jury Prize. The other works which gained the finalist statute should bear in their credits the mention “Finalist of the PeaceJam Special Jury Prize (with the year)”.
            </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><b><br></b></span></div>
            <div style="text-align: justify;">
              <span style="font-size: 18px;">
                <h3 class="mt-0">15. SECRETARIAT</h3>
                Digital Submissions can be sent to videoinfo@peacejam.org. Note any passwords or restrictions in the body of the email submission. Any correspondence or information request should be addressed to PeaceJam’s Headquarters. DVDs should be sent to PeaceJam’s head office, preferably by postal parcel by recorded delivery showing clearly the mention “PeaceJam Special Jury Prize, no commercial value”, at the latest eight days before the day the preselection Jury meets. 
                Delivery address for the material: 
                PeaceJam Foundation 1031 33rd Street, Suite 174, Denver CO 80205.
                The responsibility of sending the material is at the participant’s cost. These programs will be sent back to the participants who will request it in writing, within a period of one month after the announcement of the prize-winners, with the promise of taking in charge all the postal costs.
              </span>
            </div>
            <div style="text-align: justify;"><span style="font-size: 18px;"><b><br></b></span></div>
            <div style="text-align: justify;"><span style="font-size: 18px;">
              <h3 class="mt-0">16. ACCEPTANCE OF THE RULES</h3>
              The fact of proposing a television document for the PeaceJam Special Jury Prize implies the unreserved acceptance of the present rules, only the English text being valid.</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    mounted() {
      window.scrollTo(0, 0);
    }
  }
</script>

<style scoped>

</style>
